@import "styles/mixins";
@import "styles/theme";

.layout {
  overflow-x: hidden;
  position: relative;
}

.content {
  max-width: 1550px;
  padding: 0 90px;
  margin: 0 auto;
  @include tablet {
    width: 100%;
    max-width: 100%;
    padding: 0 30px;
  }
}

.overlay {
  position: absolute;
  left: 100%;
  height: 100%;
  background-color: white;
  top: 0;
  bottom: 0;
  z-index: 9999;
  right: 0;
  transition: all 0.3s ease-in-out;

  &__list {
    padding: 0 25px;
    margin-top: 78px;
    display: flex;
    flex-direction: column;

    &__item {
      margin-bottom: 23px;
      text-decoration: none;
      @include montserrat-semi-bold;
      font-size: $text-sm;
      color: $secondary-color;
    }
  }

  &--visible {
    left: 0;
  }
}

.header {
  height: 97px;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 9909;
  border-bottom: 1px solid transparent;
  @include tablet {
    height: 65px;
  }
  &__content {
    max-width: 1550px;
    padding: 0 90px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    @include tablet {
      padding: 0 25px;
      display: flex;
      justify-content: space-between;
    }
  }
  &__hamburger {
    display: none;
    @include tablet {
      display: block;
    }
  }
  &--border {
    transition: 0.3s all ease-in-out;
    box-shadow: 0px 2px 6px 0px rgba(128, 128, 128, 0.363);
  }
  &__logo {
    @include montserrat-extra-bold;
    font-size: $text-lg;
    color: $primary-color;
    position: relative;
    top: 10px;
    @include tablet {
      position: static;
    }
  }
  &__link {
    @include montserrat-semi-bold;
    font-size: $text-tiny !important;
    color: $secondary-color;
    width: 20%;
    text-decoration: none;
    @include tablet {
      display: none;
    }
    &:hover {
      color: $primary-color;
    }
    &:last-of-type {
      text-align: right;
    }
  }
}
