// Fonts
@mixin montserrat-light {
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
}
@mixin montserrat-regular {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}
@mixin montserrat-medium {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}
@mixin montserrat-semi-bold {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}
@mixin montserrat-bold {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}
@mixin montserrat-extra-bold {
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
}

//fonts
@mixin main-title {
  font-size: $text-xxl;
  @include desktop-sm {
    font-size: 5.2vh;
  }
  @include tablet {
    font-size: 30px;
    width: 100%;
  }
}

@mixin sub-title {
  font-size: $text-lg;
  @include tablet {
    font-size: $text-ml;
  }
}

@mixin text {
  font-size: $text-ml;
  @include desktop-sm {
    font-size: 2.2vh;
  }
  @include tablet {
    font-size: $text-sm;
  }
}

// Media Queries
$mobile-width: 320px;
$tablet-width: 768px;
$desktop-width-sm: 1024px;
$desktop-width-md: 1600px;

@mixin mobile {
  @media (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: #{$desktop-width-sm}) {
    @content;
  }
}

@mixin desktop-sm {
  @media (min-width: #{$desktop-width-sm + 1}) and (max-width: #{$desktop-width-md}) {
    @content;
  }
}

@mixin desktop-lg {
  @media (min-width: #{$desktop-width-md + 1}) {
    @content;
  }
}
