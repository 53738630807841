@font-face {
  font-family: "Montserrat";
  src: url(../assets/fonts/Montserrat-Light.ttf) format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: url(../assets/fonts/Montserrat-Regular.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url(../assets/fonts/Montserrat-Medium.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url(../assets/fonts/Montserrat-SemiBold.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url(../assets/fonts/Montserrat-Bold.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url(../assets/fonts/Montserrat-ExtraBold.ttf) format("truetype");
  font-weight: 900;
  font-style: normal;
}
