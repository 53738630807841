@import "styles/mixins";
@import "styles/theme";

.home {
  @include tablet {
    width: 100%;
  }
  @include desktop-sm {
    max-width: 100%;
  }
  &__cover {
    @include desktop-sm {
      height: calc(100vh - 97px);
      margin-bottom: 100px;
      padding-top: 6vh;
    }
    padding-top: 84px;
    @include desktop-sm {
      padding-top: 3vh;
    }
    @include tablet {
      padding-top: 56px;
      margin-bottom: 100px;
    }
    margin-bottom: 120px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    &__title {
      color: $secondary-color;
      @include montserrat-bold;
      @include main-title;
      @include desktop-sm {
        height: max-content;
      }
    }
    &__sub-title {
      color: $secondary-color-light;
      @include montserrat-medium;
      margin: 24px 0 36px 0;
      padding: 15px 0 18px 0;
      &--highlighted {
        color: $primary-color;
        @include montserrat-bold;
      }
      @include text;
      @include desktop-sm {
        margin: 0;
      }
      @include tablet {
        margin: 34px 0 56px 0;
        width: 279px;
        line-height: 27px;
      }
    }
    &__img {
      height: 60%;
      img {
        max-width: 700px;
        max-height: 600px;
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }
    &__btn {
      @include desktop-sm {
        height: 10%;
      }
      button {
        text-transform: uppercase;
        background-color: transparent;
        border: 1px solid #7253e4;
        border-radius: 4px;
        font-size: 14px;
        padding: 18px 25px;
        color: #7253e4;
        margin-bottom: 40px;
        @include desktop-sm {
          margin-bottom: 3vh;
          padding: 1.5vh 25px;
          font-size: 1.8vh;
        }
        @include montserrat-semi-bold;
        &:hover {
          background-color: $primary-color;
          box-shadow: 0px 6px 12px 0px #7253e440;
          color: white;
        }
      }
    }
  }
  &__services {
    margin-top: 16px;
    text-align: center;
    @include tablet {
      margin-top: 0;
    }
    &__head-note {
      @include montserrat-bold;
      margin-bottom: 56px;
      color: $secondary-color;
      @include sub-title;
    }
    &__title {
      color: $primary-color;
      @include montserrat-light;
      @include main-title;
      span {
        @include montserrat-bold;
        color: $primary-color;
      }
      @include tablet {
        line-height: 40px;
      }
    }
  }
  &__subscriptions {
    margin-top: 90px;
    margin-bottom: 290px;
    height: 528px;
    width: 100%;
    background-color: $secondary-color;
    border-radius: 22px;
    color: #fff;
    display: flex;
    justify-content: space-between;
    @include tablet {
      height: 400px;
    }
    @include mobile {
      margin-top: 50px;
      padding: 24px;
      flex-direction: column;
      margin-bottom: 82px;
      height: max-content;
      overflow: hidden;
    }

    &__info {
      padding: 38px 0 0 44px;
      width: 30%;
      @include mobile {
        padding: 0;
        width: 100%;
      }
    }
    &__title {
      @include montserrat-bold;
      position: relative;
      @include sub-title;

      &::after {
        display: block;
        content: "";
        background: linear-gradient(
          90.23deg,
          $primary-color-dark 0%,
          #937ee0 97.6%
        );
        border-radius: 17px;
        height: 5px;
        width: 90px;
        margin-top: 12px;
        margin-bottom: 49px;
        @include mobile {
          height: 2px;
          margin-top: 6px;
          margin-bottom: 24px;
        }
      }
    }
    &__desc {
      font-size: $text-ml;
      width: 266px;
      @include text;
      @include tablet {
        width: 100%;
      }
    }

    &__cards {
      padding: 48px 75px;
      width: 70%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include tablet {
        padding: 48px 50px 48px 65px;
      }

      @include mobile {
        margin-top: 24px;
        flex-shrink: 0;
        padding: 0;
        height: 290px;
        position: relative;
        margin-left: -24px;
        padding-left: 24px;
        justify-content: flex-start;
        width: 100vw;
        overflow-x: scroll;
        padding-right: 27vw;
      }
    }
    &__card {
      background-color: #fff;
      border-radius: 18px;
      height: 100%;
      width: 45%;
      padding: 24px 36px;
      flex-shrink: 0;

      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      cursor: pointer;
      color: $secondary-color;
      font-size: $text-lg;
      @include montserrat-light;
      transition: 0.3s all ease-in-out;
      @include tablet {
        width: 48%;
        font-size: 30px;
      }
      @include mobile {
        width: 100% !important;
        height: 290px;

        &:last-of-type {
          margin-left: 10px;
        }
      }
      @media (min-width: 769px) {
        &:hover {
          transform: scale(1.1) translateY(-70px);
          background: linear-gradient(
            153.28deg,
            $primary-color-dark 2.3%,
            #937ee0 99.31%
          ) !important;
          color: white !important;
        }
      }
      @include mobile {
        &:hover {
          background: linear-gradient(
            153.28deg,
            $primary-color-dark 2.3%,
            #937ee0 99.31%
          ) !important;
          color: white !important;
        }
      }
    }
  }
  &__workspace {
    margin-bottom: 132px;
    padding: 72px;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: space-between;
    background: linear-gradient(
      148.81deg,
      $primary-color-dark -80.12%,
      #937ee0 131.05%
    );
    border-radius: 22px;
    height: 516px;
    @media (max-width: 1200px) {
      padding: 50px;
      height: 450px;
    }
    @include tablet {
      padding: 30px;
      height: 412px;
      margin-bottom: 100px;
    }
    @include mobile {
      margin-bottom: 56px;
      height: 300px;
      padding: 24px;
    }

    &__header {
      height: 80%;
      position: relative;
    }

    &__footer {
      display: flex;
      height: 20%;
      justify-content: space-between;
      align-items: center;
    }

    &__title {
      color: white;
      top: calc(100% + 13px);
      position: absolute;
      display: flex;
      width: 100%;
      align-items: flex-end;
      @include montserrat-bold;
      @include main-title;
      @include tablet {
        top: 0;
        // width: 200px;
      }
      @media (max-width: 360px) {
        font-size: $text-ml;
      }
    }
    &__emailVisible {
      .home__workspace__title {
        transition: 0.5s all ease-in-out;
        top: 0;
      }
      .home__workspace__input {
        transition: 0.5s all ease-in;
        opacity: 1;
        visibility: visible;
      }
    }

    &__input {
      border: 2px solid #fff;
      border-radius: 8px;
      opacity: 0;
      visibility: hidden;
      height: 100%;
      width: calc(100% - 130px);
      padding: 0 20px;
      display: flex;
      align-items: center;

      @media (max-width: 1200px) {
        width: calc(100% - 110px);
      }
      @media (max-width: 900px) {
        width: calc(100% - 90px);
      }
      @include mobile {
        width: calc(100% - 65px);
        border-width: 1px;
        border-color: white;
      }
      @media (max-width: 550px) {
        width: calc(100% - 55px);
      }
      input {
        color: white;
        background-color: transparent;
        outline: none;
        border: none;
        font-size: $text-ml;
        width: 100%;
        @include montserrat-medium;
        &::placeholder {
          color: white;
        }
      }
      @include tablet {
        height: 100% !important;
        opacity: 1;
        visibility: visible;
        border-radius: 2px !important;
      }
      @include mobile {
        height: 36px;
        padding: 0 10px;
        input {
          font-size: $text-tiny !important;
        }
      }
      @media (max-width: 550px) {
        height: 40px !important;
      }
    }

    &__btn {
      width: 114px;
      height: 100%;
      display: flex;
      margin-left: auto;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      font-size: $text-md;
      @include montserrat-medium;
      position: relative;
      z-index: 99;
      background-color: white;
      color: $primary-color;
      svg {
        height: 35.2px;
        width: 35.2px;
      }

      @media (max-width: 1200px) {
        width: 100px;
        font-size: $text-sm;
        svg {
          height: 25px;
          width: 25px;
        }
      }
      @media (max-width: 900px) {
        width: 80px;
        svg {
          height: 20px;
          width: 20px;
        }
      }
      @include mobile {
        width: 55px;
        font-size: $text-nano;
        border-radius: 4px;
        svg {
          height: 17px;
          width: 17px;
        }
      }
      @media (max-width: 550px) {
        width: 45px;
        height: 40px;
        font-size: $text-nano;

        svg {
          height: 12px;
          width: 12px;
        }
      }
      @include tablet {
        border-radius: 2px !important;
      }
    }
  }
  &__email {
    &__title {
      font-size: $text-xl;
      @include montserrat-extra-bold;
      margin-bottom: 35px;
      color: $primary-color;

      @include mobile {
        font-size: $text-lg;
        margin-bottom: 16px;
      }
    }
    &__field {
      border: 2px solid $primary-color;
      border-radius: 8px;
      padding: 24px;
      width: 700px;
      height: 77px;
      position: relative;
      margin-bottom: 56px;
      @include mobile {
        border-width: 1px !important;
        border-radius: 3px !important;
      }
      input {
        outline: none;
        border: none;
        color: $primary-color;
        font-size: $text-md;
        @include montserrat-medium;
        &::placeholder {
          color: $secondary-color;

          @include montserrat-light;
          font-size: $text-md;
          @include mobile {
            font-size: 11px;
          }
        }
      }
      button {
        background-color: $primary-color;
        border-radius: 4px;
        color: white;
        @include montserrat-medium;
        font-size: $text-sm;
        position: absolute;
        height: 67px;
        width: 161px;
        right: 3px;
        top: 50%;
        transform: translateY(-50%);
      }
      @include mobile {
        width: 100%;
        height: 37px;
        margin-bottom: 45px;
        padding: 0 12px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        input {
          font-size: 11px;
        }
        button {
          height: 29px;
          width: 75px;
          font-size: 8px;
          border-radius: 2px;
        }
      }
    }
    &__links {
      color: $primary-color;
      font-size: $text-ml;
      @include montserrat-medium;

      span {
        cursor: pointer;
        display: inline-block;
        margin-right: 56px;
        @include mobile {
          display: block;
          margin-right: 0;
          font-size: 12px;
          &:first-of-type {
            margin-bottom: 9px;
          }
        }
      }
    }
  }
  &__footer {
    border-top: $border;
    margin-top: 92px;
    padding: 27px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $secondary-color;
    font-size: $text-sm;
    @include montserrat-regular;
    &__rights {
      @include mobile {
        font-size: 12px !important;
        padding-top: 5px;
      }
    }
    &__links {
      span {
        cursor: pointer;
        display: inline-block;
        margin-left: 19px;
      }
    }
    @include mobile {
      flex-direction: column-reverse;
      font-size: $text-nano;
      padding: 10px 0;
      margin-top: 32px;

      &__links {
        span {
          display: inline-block;
          margin-left: 10px;
          &:first-of-type {
            margin-left: 0 !important;
          }
        }
      }
    }
  }
}

.home__subscriptions__cards {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.home__subscriptions__cards::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
