@import "styles/theme";
@import "styles/mixins";

.carousel {
  margin-top: 122px;
  position: relative;
  @include tablet {
    margin-top: 56px;
  }

  &__item {
    position: relative;
    display: flex;
    margin-bottom: 113px;
    justify-content: space-between;
    overflow: hidden;
    @include tablet {
      flex-direction: column-reverse;
      justify-content: flex-start;
    }
    &__image {
      width: 464px;
      height: 399px;
      margin: 0 auto;
      @include tablet {
        width: 100%;
        height: 100%;
        max-width: 600px;
        max-height: 600px;
        object-fit: contain;
      }
      img {
        width: 100%;
        height: 100%;
        @include tablet {
          height: 400px;
          width: 100%;
        }
        @include mobile {
          height: 300px;
          width: 100%;
        }
      }
    }
    &__content {
      width: calc(100% - 600px);
      position: relative;
      border-radius: 11px;
      text-align: left;
      @include tablet {
        width: 100%;
      }
      &__title {
        @include montserrat-bold;
        color: $secondary-color;
        @include main-title;
        @include tablet {
          height: 60px;
        }
      }
      &__benefits {
        display: flex;
        flex-direction: column;
        margin-top: 44px;
        @include tablet {
          margin-top: 24px;
          height: 230px;
          margin-bottom: 32px;
        }
      }
      &__benefit {
        color: $secondary-color;
        @include montserrat-medium;
        @include text;
        &:not(:last-of-type) {
          margin-bottom: 44px;
        }
        display: flex;
        align-items: center;
        &__svg {
          width: 30px;
          margin-right: 31px;
          display: flex;
          align-items: center;
        }

        span {
          width: calc(100% - 30px);
        }
        @include tablet {
          line-height: 24px;
          &:not(:last-of-type) {
            margin-bottom: 30px;
          }
        }
      }
      @include tablet {
        margin-top: 40px;
      }
    }
  }
  &__btn {
    margin-bottom: 36px;
    height: 49px;
    width: 49px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background-color: $primary-color;
    position: absolute;
    bottom: 0;
    z-index: 999;
    &--prev {
      right: 55px;
    }
    &--next {
      right: 0;
    }
    &--disabled {
      background-color: #e8e2ff;
    }
    @include tablet {
      top: 40px;
    }
    @include mobile {
      top: 47px;
      width: 24px;
      height: 24px;
      svg {
        width: 13px;
        height: 13px;
      }
      &--prev {
        right: 32px;
      }
    }
  }
}
