$primary-color-dark: #573cbc;
$primary-color: #7253e4;
$secondary-color: #484848;
$secondary-color-light: #777777;

$text-xxl: 56px;
$text-xl: 48px;
$text-lg: 36px;
$text-ml: 24px;
$text-md: 20px;
$text-sm: 18px;
$text-tiny: 16px;
$text-nano: 10px;

$border: 1px solid rgba(87, 60, 188, 0.2);
