@import "./fonts.scss";
@import "./theme.scss";
@import "react-responsive-carousel/lib/styles/carousel.min.css";

html {
  margin: 0;
  padding: 0;
}
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  color: $secondary-color;
  margin: 0;
  font-family: "Circular Std", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  outline: none;
  border: none;
  cursor: pointer;
}
/* width */
*::-webkit-scrollbar {
  width: 8px;
}

/* Track */
*::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
*::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 30px;
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
  background: #555;
}
